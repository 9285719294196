import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./ProjectsContainer.module.scss";
import { Col, Row } from "../layout/Grid";
import { externalURL } from "../../config/url";
import classNames from "classnames";

const Projects = [
  {
    id: "moodboard",
    img: (
      <StaticImage
        src="../../images/project-moodboard.jpg"
        alt="Project - Moodboard"
      />
    ),
    tag: "Ecommerce",
    name: "Moodboard",
    desc: "A brand new ecommerce marketplace that gathers various brands and styles under one roof that projects the essence of the name.",
    tech: ["WooCommerce", "Payment gateway integration"],
    url: externalURL.moodboard,
  },
  {
    id: "natural-orient",
    img: (
      <StaticImage
        src="../../images/project-natural-orient.jpg"
        alt="Project - Natural Orient"
      />
    ),
    tag: "Website & Branding",
    name: "Natural Orient",
    desc: "A food solution provider's corporate website specialises in natural ingredients, food processing machinery, and technology.",
    tech: ["WordPress", "Visual design"],
    url: externalURL.naturalOrient,
  },
  {
    id: "orbiting-scientific",
    img: (
      <StaticImage
        src="../../images/project-orbiting-scientific.jpg"
        alt="Project - Orbiting Scientific"
      />
    ),
    tag: "Website",
    name: "Orbiting Scientific",
    desc: "A new company website of an authorised distributor of scientific instruments.",
    tech: ["WordPress", "Visual design"],

    url: externalURL.orbitinsScientific,
  },
  {
    id: "techbond",
    img: (
      <StaticImage
        src="../../images/project-techbond.jpg"
        alt="Project - Techbond"
      />
    ),
    tag: "Website",
    name: "Techbond",
    desc: "A corporate website for a global manufacturer and supplier of industrial adhesives.",
    tech: ["WordPress", "Visual design"],
    url: externalURL.techbond,
  },
  {
    id: "lakecity",
    img: (
      <StaticImage
        src="../../images/project-lakecity.jpg"
        alt="Project - Lakecity"
      />
    ),
    tag: "Website & Branding",
    name: "LakeCity KL",
    desc: "A real-estate branding website for a one-of-a-kind urban HOPSCA city destined to inspire and rejuvenate the thriving KL North",
    tech: ["WordPress", "Visual design"],
    url: externalURL.lakecity,
  },
  // {
  //   id: "boh",
  //   img: <StaticImage src="../../images/project-boh.jpg" alt="Project - BOH" />,
  //   tag: "Software",
  //   name: "BOH Cameronian Art Awards Judging System",
  //   desc: "A judging system for arts awards celebrating outstanding dance, music, theatre, and musical theatre performances.",
  //   tech: ["Laravel", "User-experience design"],
  // },
  {
    id: "vsi",
    img: (
      <StaticImage src="../../images/project-vsi.jpg" alt="Project - V.S.I" />
    ),
    tag: "Website & Branding",
    name: "V.S. Industry",
    desc: "A corporate website for a leading integrated electronics manufacturing services provider.",
    tech: ["WordPress", "Visual design"],
    url: externalURL.vsi,
  },
  {
    id: "esona",
    img: (
      <StaticImage src="../../images/project-esona.jpg" alt="Project - Esona" />
    ),
    tag: "Ecommerce",
    name: "Esona Online",
    desc: "A corporate ecommerce website for a company that provides sanitation, anti-bacteria, odour management, and eco-friendly low carbon solutions.",
    tech: ["WordPress", "WooCommerce"],
    url: externalURL.esona,
  },
  {
    id: "eyemeditech",
    img: (
      <StaticImage
        src="../../images/project-eyemeditech.jpg"
        alt="Project - EyeMditech"
      />
    ),
    tag: "Website & Branding",
    name: "Eyemeditech",
    desc: "A brand new corporate website for a business specialising in quality contact lenses and Silicon Hydrogel contact lenses.",
    tech: ["WordPress", "Visual design"],
    url: externalURL.eyemeditech,
  },
  {
    id: "offshore-business",
    img: (
      <StaticImage
        src="../../images/project-offshore-business.jpg"
        alt="Project - Offshore Business"
      />
    ),
    tag: "Website & Branding",
    name: "Offshore Business",
    desc: "A corporate website that serves global investors, business people, and corporations in setting up companies in popular offshore locations worldwide.",
    tech: ["WordPress", "Visual design"],
    url: externalURL.offshoreBusiness,
  },
  {
    id: "rspo",
    img: (
      <StaticImage src="../../images/project-rspo.jpg" alt="Project - RSPO" />
    ),
    tag: "Software",
    name: "RSPO General Assembly",
    desc: "Analysis software that publishes resolution reports of General Assemblies.",
    tech: ["Laravel", "User-experience design"],
    url: externalURL.rspo,
  },
  {
    id: "victor-kong",
    img: (
      <StaticImage
        src="../../images/project-vk.jpg"
        alt="Project - Victor Kong"
      />
    ),
    tag: "Website & Branding",
    name: "Victor Kong",
    desc: "A portfolio website for a Malaysian music producer and composer",
    tech: ["WordPress", "Visual design"],
    url: externalURL.victorKong,
  },
];

const ProjectsContainer = () => {
  const isBrowser = () => typeof window !== "undefined";
  const [hover, setHover] = useState(false);
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  useEffect(() => {
    if (isBrowser() && window.innerWidth >= 769) {
      let triggerstl = [];
      let triggerstl2 = [];
      const element = ref.current;
      const images = gsap.utils.toArray(element.querySelectorAll(".image"));
      const contents = gsap.utils.toArray(element.querySelectorAll(".content"));
      images.forEach((image, i) => {
        console.log("i", i);
        if (i < images.length - 1) {
          gsap.set(image, { zIndex: 100 - i });
          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: element,
              start: () => "top -" + window.innerHeight * (i + 0.5),
              end: () => "+=" + window.innerHeight,
              scrub: 1,
              snap: {
                snapTo: 1,
                duration: { min: 0.5, max: 0.9 },
                delay: 0,
              },
              toggleActions: "play none reverse none",
              invalidateOnRefresh: true,
              // markers: true,
            },
          });
          tl.to(image, { height: 0 });
          triggerstl.push(tl.scrollTrigger);
        }
      });
      contents.forEach((content, j) => {
        console.log("j", j);
        gsap.set(content, { zIndex: 100 - j });
        const tl2 = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: () => "top -" + window.innerHeight * j,
            end: () => "+=" + window.innerHeight,
            scrub: 1,
            toggleActions: "play none reverse none",
            invalidateOnRefresh: true,
            // markers: true,
          },
        });
        tl2
          .to(content, { duration: 0.33, opacity: 1, top: "5%" })
          .to(content, { duration: 0.1, opacity: 0, top: "0%" }, 0.7)
          .to(content, { pointerEvents: "none" });
        triggerstl2.push(tl2.scrollTrigger);
      });
      const animation = ScrollTrigger.create({
        trigger: element,
        scrub: true,
        // markers: true,
        pin: true,
        start: () => "top top",
        end: () => "+=" + images.length * window.innerHeight,
        invalidateOnRefresh: true,
      });
      return () => {
        triggerstl.forEach((tl) => tl.kill());
        triggerstl2.forEach((tl2) => tl2.kill());
        animation.kill();
      };
    }
  }, []);
  if (isBrowser() && window.innerWidth < 769)
    return (
      <section className="section wrapper">
        {Projects.map((item, index) => {
          return (
            <div key={index} className={styles.projectWrapper}>
              {item.url && (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.imgLink}
                >
                  {item.img}
                </a>
              )}
              <div className="h6">{item.tag}</div>
              <h2 className="h3">{item.name}</h2>
              <p>{item.desc}</p>
              <ul className="custom-list">
                {item.tech.map((tech, i) => {
                  return <li key={i}>{tech}</li>;
                })}
              </ul>
              {item.url && (
                <a
                  href={item.url}
                  className="btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Project
                </a>
              )}
            </div>
          );
        })}
      </section>
    );
  return (
    <section ref={ref}>
      <div className="section wrapper">
        <Row>
          <Col width="50%" className={styles["wrap"]}>
            {Projects.map((item, index) => {
              return (
                <div key={index} className={`image ${styles["image"]}`}>
                  {item.url && (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                      onMouseOver={() => setHover(true)}
                      onFocus={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      className={hover ? styles.hovered : null}
                    >
                      {item.img}
                    </a>
                  )}
                </div>
              );
            })}
          </Col>
          <Col width="42%" className={styles["wrap"]}>
            {Projects.map((item, index) => {
              return (
                <div key={index} className={`content ${styles["content"]}`}>
                  <div className="h6">{item.tag}</div>
                  <h2 className="h3">{item.name}</h2>
                  <p>{item.desc}</p>
                  <ul className="custom-list">
                    {item.tech.map((tech, i) => {
                      return <li key={i}>{tech}</li>;
                    })}
                  </ul>
                  {item.url && (
                    <a
                      href={item.url}
                      className={classNames(
                        "btn",
                        hover ? styles.hovered : null
                      )}
                      target="_blank"
                      rel="noreferrer"
                      onMouseOver={() => setHover(true)}
                      onFocus={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                    >
                      View Project
                    </a>
                  )}
                </div>
              );
            })}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ProjectsContainer;
